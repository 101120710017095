import revive_payload_client_bgB6XurGpg from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.1_ioredis@5_xegldwwfzzdwutap5x5mo7jmse/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_yTqln9ROOG from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.1_ioredis@5_xegldwwfzzdwutap5x5mo7jmse/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_PKF3GA4bQm from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.1_ioredis@5_xegldwwfzzdwutap5x5mo7jmse/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kTyv5Nne2Z from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.1_ioredis@5_xegldwwfzzdwutap5x5mo7jmse/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_aq2ZKsIU7c from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.1_ioredis@5_xegldwwfzzdwutap5x5mo7jmse/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_w2Nv1KMFpf from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.1_ioredis@5_xegldwwfzzdwutap5x5mo7jmse/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_6UTbhyGIha from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.1_ioredis@5_xegldwwfzzdwutap5x5mo7jmse/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/checkup/checkup/admin/.nuxt/components.plugin.mjs";
import prefetch_client_UipWcHNNDi from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.17.6_encoding@0.1.13_eslint@8.57.1_ioredis@5_xegldwwfzzdwutap5x5mo7jmse/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import supabase_client_Vu6b6nytQn from "/home/runner/work/checkup/checkup/admin/src/plugins/supabase.client.ts";
import sentry_client_KAXFuL2wum from "/home/runner/work/checkup/checkup/admin/src/plugins/sentry.client.ts";
import dayjs_client_A2bzKURDlV from "/home/runner/work/checkup/checkup/admin/src/plugins/dayjs.client.ts";
import gtag_6Z9lu4VVCL from "/home/runner/work/checkup/checkup/admin/src/plugins/gtag.ts";
import i18n_YCWKwfVXF6 from "/home/runner/work/checkup/checkup/admin/src/plugins/i18n.ts";
import qrcode_client_gU8ufMWnQ5 from "/home/runner/work/checkup/checkup/admin/src/plugins/qrcode.client.ts";
import vuedraggable_ajhfMTWOKD from "/home/runner/work/checkup/checkup/admin/src/plugins/vuedraggable.ts";
export default [
  revive_payload_client_bgB6XurGpg,
  unhead_yTqln9ROOG,
  router_PKF3GA4bQm,
  payload_client_kTyv5Nne2Z,
  navigation_repaint_client_aq2ZKsIU7c,
  check_outdated_build_client_w2Nv1KMFpf,
  chunk_reload_client_6UTbhyGIha,
  components_plugin_KR1HBZs4kY,
  prefetch_client_UipWcHNNDi,
  supabase_client_Vu6b6nytQn,
  sentry_client_KAXFuL2wum,
  dayjs_client_A2bzKURDlV,
  gtag_6Z9lu4VVCL,
  i18n_YCWKwfVXF6,
  qrcode_client_gU8ufMWnQ5,
  vuedraggable_ajhfMTWOKD
]